.swiper {
  width: 380px;
  height: 500px;
}

@media screen and (max-width: 575px) {
    .swiper {
        width: 350px;
      }
}

.swiper-slide {
  width: 380px;
  height: 600px;
}

.pricing {
    padding-top: 20px;
    text-align: center;
    min-height: 100vh;
    display: grid;
    place-items: center;
}
.pricing .plans {
    width: 100%;
}

.pricing h4 {
    color: white;
}

.pricing #cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 2rem auto;
    text-align: center;
}

.pricing 
.card-left,
.card-middle,
.card-right {
    padding: 1.3rem;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.75);
}

.pricing .card-middle {
    padding: 3rem 1.3rem;
    background: #B5EAD7;
    /* color: white; */
    border-radius: 10px;
}

.pricing
.card-middle p,
.card-left p,
.card-right p {
    font-size: medium;
}

.pricing
.card-middle h3,
.card-left h3,
.card-right h3 {
    font-weight: bold;
}

.pricing
.card-middle h3 span,
.card-left h3 span,
.card-right h3 span{
    font-size: medium;
    font-weight: 100;
}

.pricing
.card-middle p,
.card-left p,
.card-right p {
    color: hsl(233, 13%, 49%);
}

.pricing
.card-left {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.pricing .card-right {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.pricing #card hr {
    width: 80%;
    margin: auto;
    opacity: 0.2;
}

.pricing button {
    width: 85%;
    margin: 1.5rem auto 0.5rem auto;
    padding: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
    transition-duration: 3000;
    /* color: white; */
    background-color: #B5EAD7;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
}

.pricing .card-middle button {
    /* color: #B5EAD7; */
    background-color: white;
}

.pricing .card-middle button:hover {
    border: 1px solid white;
    background-color: transparent;
}

.pricing button:hover {
    background-color: transparent;
    border: 1px solid #B5EAD7;
}

/*Media queries*/

@media only screen and (max-width: 760px) {
    .pricing #cards {
        flex-direction: column;
    }

    .pricing
    .card-left,
    .card-middle,
    .card-right {
        margin: 1rem;
        border-radius: 10px;
        width: 90%;
    }
}